@font-face {
  font-family: Space Grotesk;
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: local(Space Grotesk Light), local(SpaceGrotesk-Light), url("SpaceGrotesk-Light.77a13055.ttf") format("truetype");
}

@font-face {
  font-family: Space Grotesk;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: local(Space Grotesk Regular), local(SpaceGrotesk-Regular), url("SpaceGrotesk-Regular.fe79eafd.ttf") format("truetype");
}

@font-face {
  font-family: Space Grotesk;
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: local(Space Grotesk Medium), local(SpaceGrotesk-Medium), url("SpaceGrotesk-Medium.cc218890.ttf") format("truetype");
}

@font-face {
  font-family: Space Grotesk;
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: local(Space Grotesk SemiBold), local(SpaceGrotesk-SemiBold), url("SpaceGrotesk-SemiBold.ec0c7c53.ttf") format("truetype");
}

@font-face {
  font-family: Space Grotesk;
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: local(Space Grotesk Bold), local(SpaceGrotesk-Bold), url("SpaceGrotesk-Bold.e82a7b61.ttf") format("truetype");
}

@font-face {
  font-family: Marbla;
  src: url("MarblaVF.98e58d02.woff2") format("woff2") tech(variations), url("MarblaVF.ecb7d9e4.woff") format("woff");
}

.opener-word {
  font-variation-settings: "iktr" 100, "blln" 0, "curv" 0;
  font-family: Marbla;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: Space Grotesk, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.pointer-events-none {
  pointer-events: none;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  inset: 0;
}

.bottom-10 {
  bottom: 2.5rem;
}

.bottom-\[10vh\] {
  bottom: 10vh;
}

.left-0 {
  left: 0;
}

.left-8 {
  left: 2rem;
}

.right-0 {
  right: 0;
}

.right-10 {
  right: 2.5rem;
}

.right-6 {
  right: 1.5rem;
}

.right-8 {
  right: 2rem;
}

.top-0 {
  top: 0;
}

.top-6 {
  top: 1.5rem;
}

.top-\[15vh\] {
  top: 15vh;
}

.top-\[8vh\] {
  top: 8vh;
}

.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-40 {
  z-index: 40;
}

.z-\[-1\] {
  z-index: -1;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.col-start-1 {
  grid-column-start: 1;
}

.col-start-2 {
  grid-column-start: 2;
}

.row-start-1 {
  grid-row-start: 1;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-24 {
  margin-bottom: 6rem;
}

.mb-32 {
  margin-bottom: 8rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-24 {
  margin-top: 6rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-nav-height-m {
  margin-top: 54px;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-12 {
  height: 3rem;
}

.h-\[0\.85em\] {
  height: .85em;
}

.h-\[1\.5vw\] {
  height: 1.5vw;
}

.h-\[1px\] {
  height: 1px;
}

.h-\[2vw\] {
  height: 2vw;
}

.h-\[70px\] {
  height: 70px;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.max-h-96 {
  max-height: 24rem;
}

.w-12 {
  width: 3rem;
}

.w-16 {
  width: 4rem;
}

.w-2\/3 {
  width: 66.6667%;
}

.w-20 {
  width: 5rem;
}

.w-3\/4 {
  width: 75%;
}

.w-4\/5 {
  width: 80%;
}

.w-\[0\.85em\] {
  width: .85em;
}

.w-\[1\.5vw\] {
  width: 1.5vw;
}

.w-\[2vw\] {
  width: 2vw;
}

.w-\[5vw\] {
  width: 5vw;
}

.w-\[70px\] {
  width: 70px;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.max-w-centered-content {
  max-width: 920px;
}

.grow {
  flex-grow: 1;
}

.translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-\[-100\%\] {
  --tw-translate-y: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-\[110\%\] {
  --tw-translate-y: 110%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-full {
  --tw-translate-y: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-45 {
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-90 {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes dot-blink {
  0%, 20% {
    opacity: 0;
    transform: scale(0);
  }

  20% {
    transform: translateX(30%);
  }

  25% {
    transform: translateX(-30%);
  }

  30% {
    transform: translateX(30%);
  }

  35% {
    transform: translateX(-30%);
  }

  40% {
    transform: translateX(30%);
  }

  45% {
    transform: translateX(-30%);
  }

  50% {
    transform: translateX(30%);
  }

  55% {
    transform: translateX(-30%);
  }

  60% {
    transform: translateX(30%);
  }

  65% {
    transform: translateX(-30%);
  }

  70% {
    transform: translateX(30%);
  }

  75% {
    transform: translateX(-30%);
  }

  80% {
    transform: translateX(30%);
  }

  85% {
    transform: translateX(-30%);
  }

  90% {
    transform: translateX(30%);
  }

  95% {
    transform: translateX(-30%);
  }

  10%, 100% {
    opacity: .25;
    transform: scale(3);
  }
}

.animate-dot-blink {
  animation: 7s ease-in-out 2s infinite dot-blink;
}

@keyframes eyes-blink {
  0%, 20% {
    opacity: 0;
  }

  20%, 30% {
    transform: scaleY(0);
  }

  30%, 50% {
    opacity: 1;
    transform: scaleY(.25);
  }

  50%, 95% {
    opacity: 1;
    transform: scaleY(1);
  }

  100% {
    opacity: 1;
    transform: scaleY(0);
  }
}

.animate-eyes-blink {
  animation: 4s ease-in-out infinite alternate eyes-blink;
}

.cursor-pointer {
  cursor: pointer;
}

.resize {
  resize: both;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-body {
  grid-template-columns: 16px 1fr 16px;
}

.grid-rows-1 {
  grid-template-rows: repeat(1, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.content-start {
  align-content: flex-start;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-stretch {
  justify-content: stretch;
}

.gap-12 {
  gap: 3rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-\[1vw\] {
  gap: 1vw;
}

.self-end {
  align-self: flex-end;
}

.justify-self-center {
  justify-self: center;
}

.overflow-hidden {
  overflow: hidden;
}

.scroll-smooth {
  scroll-behavior: smooth;
}

.hyphens-manual {
  -webkit-hyphens: manual;
  hyphens: manual;
}

.rounded {
  border-radius: .25rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-xl {
  border-radius: .75rem;
}

.border {
  border-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-r {
  border-right-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-black {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}

.border-black\/20 {
  border-color: #0003;
}

.border-white\/20 {
  border-color: #fff3;
}

.border-yellow\/0 {
  border-color: #ebf57e00;
}

.bg-purple {
  --tw-bg-opacity: 1;
  background-color: rgb(8 1 42 / var(--tw-bg-opacity));
}

.bg-purple\/10 {
  background-color: #08012a1a;
}

.bg-purple\/60 {
  background-color: #08012a99;
}

.bg-purple\/90 {
  background-color: #08012ae6;
}

.bg-soft-pink {
  --tw-bg-opacity: 1;
  background-color: rgb(255 235 255 / var(--tw-bg-opacity));
}

.bg-soft-pink\/80 {
  background-color: #ffebffcc;
}

.bg-white\/5 {
  background-color: #ffffff0d;
}

.bg-white\/90 {
  background-color: #ffffffe6;
}

.bg-yellow\/10 {
  background-color: #ebf57e1a;
}

.bg-yellow\/20 {
  background-color: #ebf57e33;
}

.bg-grain {
  background-image: url("grain.0e751433.png");
}

.bg-radial-gradient-red {
  background-image: radial-gradient(47.7% 47.7%, #f57e7e80 0%, #d9d9d900 100%);
}

.bg-radial-gradient-yellow {
  background-image: radial-gradient(51.08% 51.08%, #ebf57e80 0%, #d9d9d900 100%);
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pt-12 {
  padding-top: 3rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pt-24 {
  padding-top: 6rem;
}

.pt-\[58\.86\%\] {
  padding-top: 58.86%;
}

.text-center {
  text-align: center;
}

.text-\[25vw\] {
  font-size: 25vw;
}

.text-\[38px\] {
  font-size: 38px;
}

.text-x1 {
  font-size: 12px;
  line-height: 22px;
}

.text-x2 {
  font-size: 16px;
  line-height: 140%;
}

.text-x3 {
  font-size: 18px;
  line-height: 28px;
}

.text-x4 {
  font-size: 20px;
  line-height: 30px;
}

.text-x5 {
  font-size: 24px;
  line-height: 160%;
}

.text-x7 {
  font-size: 32px;
  line-height: 44px;
}

.text-x9 {
  font-size: 56px;
  line-height: 120%;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semi {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.leading-\[0\.85\] {
  line-height: .85;
}

.leading-none {
  line-height: 1;
}

.tracking-wide {
  letter-spacing: .025em;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-black\/60 {
  color: #0009;
}

.text-orange {
  --tw-text-opacity: 1;
  color: rgb(241 183 101 / var(--tw-text-opacity));
}

.text-soft-pink {
  --tw-text-opacity: 1;
  color: rgb(255 235 255 / var(--tw-text-opacity));
}

.text-soft-pink\/70 {
  color: #ffebffb3;
}

.text-yellow {
  --tw-text-opacity: 1;
  color: rgb(235 245 126 / var(--tw-text-opacity));
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.opacity-0 {
  opacity: 0;
}

.opacity-80 {
  opacity: .8;
}

.mix-blend-multiply {
  mix-blend-mode: multiply;
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow {
  --tw-drop-shadow: drop-shadow(0 1px 2px #0000001a) drop-shadow(0 1px 1px #0000000f);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-active-link {
  --tw-drop-shadow: drop-shadow(0px 0px 5px #f1b765);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-blur {
  --tw-backdrop-blur: blur(8px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-150 {
  transition-duration: .15s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.hover\:border-yellow\/30:hover {
  border-color: #ebf57e4d;
}

.hover\:bg-\[\#F3E9D8\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(243 233 216 / var(--tw-bg-opacity));
}

.hover\:bg-yellow\/20:hover {
  background-color: #ebf57e33;
}

@media (width >= 768px) {
  .md\:visible {
    visibility: visible;
  }

  .md\:static {
    position: static;
  }

  .md\:inset-auto {
    inset: auto;
  }

  .md\:bottom-\[20vh\] {
    bottom: 20vh;
  }

  .md\:left-20 {
    left: 5rem;
  }

  .md\:right-20 {
    right: 5rem;
  }

  .md\:top-\[20vh\] {
    top: 20vh;
  }

  .md\:top-\[30vh\] {
    top: 30vh;
  }

  .md\:flex {
    display: flex;
  }

  .md\:hidden {
    display: none;
  }

  .md\:h-\[0\.75vw\] {
    height: .75vw;
  }

  .md\:h-\[85px\] {
    height: 85px;
  }

  .md\:h-auto {
    height: auto;
  }

  .md\:w-24 {
    width: 6rem;
  }

  .md\:w-3\/4 {
    width: 75%;
  }

  .md\:w-3\/5 {
    width: 60%;
  }

  .md\:w-\[0\.75vw\] {
    width: .75vw;
  }

  .md\:w-\[85px\] {
    width: 85px;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:gap-10 {
    gap: 2.5rem;
  }

  .md\:gap-\[0\.25vw\] {
    gap: .25vw;
  }

  .md\:gap-\[0\.5vw\] {
    gap: .5vw;
  }

  .md\:bg-white\/0 {
    background-color: #fff0;
  }

  .md\:text-x2 {
    font-size: 16px;
    line-height: 140%;
  }

  .md\:text-x3 {
    font-size: 18px;
    line-height: 28px;
  }

  .md\:text-x4 {
    font-size: 20px;
    line-height: 30px;
  }

  .md\:text-x5 {
    font-size: 24px;
    line-height: 160%;
  }

  .md\:text-x6 {
    font-size: 28px;
    line-height: 36px;
  }

  .md\:text-x7 {
    font-size: 32px;
    line-height: 44px;
  }
}

@media (width >= 1024px) {
  .lg\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .lg\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .lg\:mb-24 {
    margin-bottom: 6rem;
  }

  .lg\:mb-32 {
    margin-bottom: 8rem;
  }

  .lg\:mb-40 {
    margin-bottom: 10rem;
  }

  .lg\:mt-nav-height-l {
    margin-top: 70px;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:grid {
    display: grid;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:h-\[100px\] {
    height: 100px;
  }

  .lg\:w-3\/4 {
    width: 75%;
  }

  .lg\:w-3\/5 {
    width: 60%;
  }

  .lg\:w-32 {
    width: 8rem;
  }

  .lg\:w-\[100px\] {
    width: 100px;
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .lg\:grid-cols-body-lg {
    grid-template-columns: minmax(32px, 1fr) minmax(1px, 1600px) minmax(32px, 1fr);
  }

  .lg\:text-left {
    text-align: left;
  }

  .lg\:text-\[15vw\] {
    font-size: 15vw;
  }

  .lg\:text-\[64px\] {
    font-size: 64px;
  }

  .lg\:text-x3 {
    font-size: 18px;
    line-height: 28px;
  }

  .lg\:text-x4 {
    font-size: 20px;
    line-height: 30px;
  }

  .lg\:text-x5 {
    font-size: 24px;
    line-height: 160%;
  }

  .lg\:text-x7 {
    font-size: 32px;
    line-height: 44px;
  }

  .lg\:text-x9 {
    font-size: 56px;
    line-height: 120%;
  }

  .lg\:font-semi {
    font-weight: 600;
  }

  .lg\:odd\:border-r:nth-child(odd) {
    border-right-width: 1px;
  }
}

@media (width >= 1280px) {
  .xl\:text-x8 {
    font-size: 47px;
    line-height: 125%;
  }
}

@media (width >= 1536px) {
  .\32 xl\:text-x9 {
    font-size: 56px;
    line-height: 120%;
  }
}

.global__layout-align {
  grid-column: 2;
}

iframe {
  object-fit: contain;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.writer :first-child {
  margin-top: 0;
}

.writer :last-child {
  margin-bottom: 0;
}

.writer * {
  color: inherit;
}

.writer a {
  color: inherit;
  transition: color var(--link-transition);
  text-decoration: underline;
}

.writer a:hover {
  color: var(--orange);
}

.writer p {
  margin-bottom: 1em;
}

.strichschriftzeichen {
  background-color: var(--light-orange);
  margin-top: var(--nav-height);
}

.strichschriftzeichen__header {
  margin: var(--spacer-48) auto;
  text-align: center;
  max-width: 450px;
}

@media screen and (width >= 800px) {
  .strichschriftzeichen__header {
    max-width: 550px;
  }
}

@media screen and (width >= 1000px) {
  .strichschriftzeichen__header {
    margin: calc(2 * var(--spacer-48)) auto;
    width: 100%;
    max-width: 750px;
  }
}

.strichschriftzeichen__header-headline {
  font-size: 28px;
  font-weight: var(--semi);
  margin: 0;
}

@media screen and (width >= 600px) {
  .strichschriftzeichen__header-headline {
    font-size: 28px;
  }
}

@media screen and (width >= 800px) {
  .strichschriftzeichen__header-headline {
    font-size: 40px;
  }
}

@media screen and (width >= 1000px) {
  .strichschriftzeichen__header-headline {
    font-size: 56px;
  }
}

.strichschriftzeichen__icon-wrapper {
  margin: 0 auto;
  margin-top: var(--spacer-28);
  width: 80vw;
  height: 0;
  padding-top: 80vw;
  position: relative;
}

@media screen and (width >= 500px) {
  .strichschriftzeichen__icon-wrapper {
    margin-top: var(--spacer-48);
    width: 400px;
    padding-top: 400px;
  }
}

.strichschriftzeichen__icon {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.strichschriftzeichen__description {
  margin: var(--spacer-48) auto;
  max-width: 850px;
  font-size: 1em;
  line-height: 1.4;
}

@media screen and (width >= 1000px) {
  .strichschriftzeichen__description {
    margin: calc(2 * var(--spacer-48)) auto;
    font-size: 1.5em;
  }
}

.strichschriftzeichen__back-button-wrapper {
  margin: var(--spacer-48) auto;
  justify-content: center;
  max-width: 850px;
  display: flex;
}

@media screen and (width >= 1000px) {
  .strichschriftzeichen__back-button-wrapper {
    margin: calc(2 * var(--spacer-48)) auto;
  }
}

.strichschriftzeichen__back-button {
  background-color: none;
  color: #000;
  padding: var(--spacer-8) var(--spacer-28);
  margin-top: var(--spacer-24);
  transition: all var(--link-transition);
  border: 1px solid #000;
  border-radius: 6px;
  text-decoration: none;
  display: inline-block;
}

@media screen and (width >= 1000px) {
  .strichschriftzeichen__back-button {
    padding: var(--spacer-12) var(--spacer-28);
    margin-top: var(--spacer-32);
  }
}

.strichschriftzeichen__back-button:hover {
  background-color: var(--green);
}

.strichschriftzeichen__description-headline {
  font-size: 1.25em;
  font-weight: var(--semi);
}

.default-text {
  margin: var(--spacer-48) auto;
  max-width: 850px;
  font-size: 1em;
  line-height: 1.4;
}

@media screen and (width >= 1000px) {
  .default-text {
    margin: calc(2 * var(--spacer-48)) auto;
    font-size: 1.5em;
  }
}

.footer__bottom {
  margin: var(--spacer-16) 0;
  justify-content: space-between;
  font-size: 12px;
  display: flex;
}

.footer__link-since {
  color: inherit;
  transition: color var(--link-transition);
  text-decoration: none;
}

.footer__link-since:hover {
  color: var(--orange);
}

.footer__sub-nav {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.footer__sub-nav-link {
  color: inherit;
  transition: color var(--link-transition);
  text-decoration: none;
}

.footer__sub-nav-link:hover {
  color: var(--orange);
}

.navigation--slide-up {
  transform: translateY(-100%);
}

.navigation--bg-green {
  background-color: #f8fff4b3;
}

.navigation--bg-orange {
  background-color: #fff7eab3;
}

.navigation--bg-grey {
  background-color: #f2f2f2b3;
}

.navigation--open {
  background: #ffffffe6;
  bottom: 0;
}

.navigation__inner {
  padding: var(--spacer-16) 0;
  border-bottom: 1px solid #000;
  justify-content: space-between;
  display: flex;
}

@media screen and (width >= 1000px) {
  .navigation__inner {
    padding: var(--spacer-24) 0;
  }
}

.navigation--open .navigation__inner {
  border-bottom: unset;
}

.navigation__logo {
  font-weight: var(--bold);
  color: inherit;
  opacity: 1;
  transition: all var(--link-transition);
  font-size: 18px;
  line-height: 20px;
  text-decoration: none;
}

@media screen and (width >= 1000px) {
  .navigation__logo {
    font-size: 24px;
    line-height: 28px;
  }
}

.navigation__logo:hover {
  opacity: .6;
}

.navigation__menu-button {
  background: unset;
  border: unset;
  font-size: 16px;
  font-weight: var(--semi);
  cursor: pointer;
  color: #000;
  transition: color var(--link-transition);
  padding: 0;
  line-height: 20px;
}

@media screen and (width >= 1000px) {
  .navigation__menu-button {
    font-size: 20px;
    line-height: 28px;
  }
}

.navigation__menu-button:hover {
  color: var(--red);
}

.navigation__overlay-nav-list {
  text-align: center;
  opacity: 0;
  border-top: 1px solid #000;
  width: 100%;
  max-width: 750px;
  height: 0;
  margin: 0;
  padding: 0;
  list-style: none;
  transition: opacity .25s;
  transform: translateX(-1000%);
}

@media screen and (width >= 600px) {
  .navigation__overlay-nav-list {
    width: 75vw;
  }
}

.navigation--open .navigation__overlay-nav-list {
  margin: calc(var(--spacer-48) * 2) auto 0;
  opacity: 1;
  height: auto;
  transform: translateX(0);
}

@media screen and (width >= 1000px) {
  .navigation--open .navigation__overlay-nav-list {
    margin: calc(var(--spacer-48) * 3) auto 0;
  }
}

.navigation__overlay-nav-item {
  padding: var(--spacer-24) 0;
  font-size: 20px;
  font-weight: var(--semi);
  border-bottom: 1px solid #000;
}

@media screen and (width >= 1000px) {
  .navigation__overlay-nav-item {
    font-size: 48px;
  }
}

.navigation__overlay-nav-link {
  color: inherit;
  transition: color var(--link-transition);
  text-decoration: none;
}

.navigation__overlay-nav-link:hover {
  color: var(--orange);
}

.hero-two-panel-text-image__headline {
  line-height: 1.1em;
  font-weight: var(--semi);
  margin: var(--spacer-48) 0 var(--spacer-32);
  text-align: center;
}

@media screen and (width >= 760px) {
  .hero-two-panel-text-image__headline {
    text-align: left;
    margin: calc(1.5 * var(--spacer-48)) 0;
  }
}

@media screen and (width >= 1000px) {
  .hero-two-panel-text-image__headline {
    margin: calc(3 * var(--spacer-48)) 0;
    text-align: left;
  }
}

.hero-two-panel-text-image__image-box {
  margin-bottom: var(--spacer-32);
  place-self: center;
  width: 80%;
  max-width: 400px;
  height: 0;
  padding-top: 150px;
  position: relative;
}

@media screen and (width >= 760px) {
  .hero-two-panel-text-image__image-box {
    margin-bottom: unset;
    padding-top: 200px;
  }
}

@media screen and (width >= 1000px) {
  .hero-two-panel-text-image__image-box {
    padding-top: 400px;
  }
}

.hero-two-panel-text-image__image {
  object-fit: contain;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.hero-two-panel-text-image__quick-links {
  border-top: 1px solid #000;
}

@media screen and (width >= 760px) {
  .hero-two-panel-text-image__quick-links {
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    grid-template-columns: repeat(2, minmax(1px, 1fr));
    display: grid;
  }
}

.hero-two-panel-text-image__quick-link-wrapper {
  padding: var(--spacer-16) 0;
  border-bottom: 1px solid #000;
}

@media screen and (width >= 760px) {
  .hero-two-panel-text-image__quick-link-wrapper {
    border-right: 1px solid #000;
    border-bottom: unset;
    justify-content: center;
    display: flex;
  }

  .hero-two-panel-text-image__quick-link-wrapper:last-child {
    border-right: unset;
  }
}

@media screen and (width >= 1000px) {
  .hero-two-panel-text-image__quick-link-wrapper {
    padding: var(--spacer-24) 0;
  }
}

.hero-two-panel-text-image__quick-link-wrapper-inner {
  cursor: pointer;
  align-items: center;
  display: flex;
}

@media screen and (width >= 1000px) {
  .hero-two-panel-text-image__quick-link-wrapper-inner {
    justify-content: center;
  }
}

.hero-two-panel-text-image__quick-link {
  text-align: center;
  font-size: 18px;
  font-weight: var(--semi);
  color: #000;
  transition: color var(--link-transition);
  text-decoration: none;
  position: relative;
}

@media screen and (width >= 1000px) {
  .hero-two-panel-text-image__quick-link {
    font-size: 28px;
  }
}

@media screen and (width >= 1200px) {
  .hero-two-panel-text-image__quick-link {
    font-size: 36px;
  }
}

.hero-two-panel-text-image__quick-link-wrapper-inner:hover .hero-two-panel-text-image__quick-link {
  color: var(--orange);
}

.hero-two-panel-text-image__quick-link-pfeil {
  object-fit: contain;
  margin-left: var(--spacer-8);
  fill: #000;
  transition: fill var(--link-transition);
  width: 20px;
  height: 100%;
}

@media screen and (width >= 1000px) {
  .hero-two-panel-text-image__quick-link-pfeil {
    margin-left: var(--spacer-16);
    width: 40px;
  }
}

.hero-two-panel-text-image__quick-link-wrapper-inner:hover .hero-two-panel-text-image__quick-link-pfeil {
  fill: var(--orange);
}

.hero-text {
  margin: var(--spacer-48) 0;
}

@media screen and (width >= 1000px) {
  .hero-text {
    margin: calc(3 * var(--spacer-48)) 0;
  }
}

.hero-text__kicker {
  margin: 0 0 var(--spacer-24);
  font-size: 13px;
}

@media screen and (width >= 1000px) {
  .hero-text__kicker {
    margin: 0 0 var(--spacer-48);
    font-size: 24px;
  }
}

.hero-text__text {
  max-width: 850px;
  margin: 0 auto;
  font-size: 18px;
  line-height: 25px;
}

@media screen and (width >= 600px) {
  .hero-text__text {
    width: 75vw;
  }
}

@media screen and (width >= 1000px) {
  .hero-text__text {
    font-size: 36px;
    line-height: 45px;
  }
}

.collection-page-header {
  margin: var(--spacer-48) auto;
  text-align: center;
  max-width: 450px;
}

@media screen and (width >= 800px) {
  .collection-page-header {
    max-width: 550px;
  }
}

@media screen and (width >= 1000px) {
  .collection-page-header {
    margin: calc(2 * var(--spacer-48)) auto;
    width: 100%;
    max-width: 750px;
  }
}

.collection-page-header__headline {
  font-size: 28px;
  font-weight: var(--semi);
  margin: 0;
}

@media screen and (width >= 600px) {
  .collection-page-header__headline {
    font-size: 28px;
  }
}

@media screen and (width >= 800px) {
  .collection-page-header__headline {
    font-size: 40px;
  }
}

@media screen and (width >= 1000px) {
  .collection-page-header__headline {
    font-size: 56px;
  }
}

.collection-page-header__text {
  margin: var(--spacer-16) 0 0;
  font-size: 18px;
  line-height: 1.3;
}

@media screen and (width >= 600px) {
  .collection-page-header__text {
    margin: var(--spacer-24) 0 0;
    font-size: 20px;
  }
}

@media screen and (width >= 800px) {
  .collection-page-header__text {
    margin: var(--spacer-32) 0 0;
    font-size: 24px;
  }
}

@media screen and (width >= 1000px) {
  .collection-page-header__text {
    font-size: 32px;
  }
}

.collection-page-cta {
  margin-bottom: var(--spacer-48);
}

@media screen and (width >= 1000px) {
  .collection-page-cta {
    margin-bottom: calc(2 * var(--spacer-48));
  }
}

.collection-page-cta__inner {
  text-align: center;
  font-weight: var(--semi);
  max-width: 450px;
  margin: 0 auto;
  font-size: 14px;
  line-height: 1.5;
}

@media screen and (width >= 1000px) {
  .collection-page-cta__inner {
    font-size: 1em;
  }
}

.collection-page-cta__button {
  background-color: var(--green);
  color: #000;
  padding: var(--spacer-8) var(--spacer-28);
  margin-top: var(--spacer-24);
  transition: all var(--link-transition);
  border: none;
  border-radius: 6px;
  text-decoration: none;
  display: inline-block;
}

@media screen and (width >= 1000px) {
  .collection-page-cta__button {
    padding: var(--spacer-12) var(--spacer-28);
    margin-top: var(--spacer-32);
  }
}

.collection-page-cta__button:hover {
  color: #fff;
  background-color: #000;
}

.collection-page-list {
  margin: 0 auto var(--spacer-48);
  width: 100%;
  max-width: 750px;
}

@media screen and (width >= 600px) {
  .collection-page-list {
    width: 75vw;
  }
}

@media screen and (width >= 1000px) {
  .collection-page-list {
    margin: 0 auto calc(2 * var(--spacer-48));
  }
}

.collection-page-list__list {
  margin: 0;
  padding: 0;
}

:root {
  --collection-list-item-col: 70px;
  --collection-list-item-col-abk: 80px;
}

@media screen and (width >= 1000px) {
  :root {
    --collection-list-item-col: 100px;
    --collection-list-item-col-abk: 120px;
  }
}

.collection-list-item__header {
  grid-template-columns: var(--collection-list-item-col) 1fr;
  font-weight: var(--semi);
  padding-bottom: var(--spacer-8);
  letter-spacing: .025em;
  border-bottom: 1px solid #000;
  font-size: 12px;
  list-style: none;
  display: grid;
}

.collection-list-item__header--abkuerzung {
  grid-template-columns: var(--collection-list-item-col-abk) 1fr;
}

.collection-list-item {
  grid-template-columns: var(--collection-list-item-col) 1fr;
  padding: var(--spacer-16) 0;
  border-bottom: 1px solid #000;
  list-style: none;
  display: grid;
}

.collection-list-item--abkuerzung {
  grid-template-columns: var(--collection-list-item-col-abk) 1fr;
}

@media screen and (width >= 1000px) {
  .collection-list-item {
    padding: var(--spacer-24) 0;
  }
}

.collection-list-item__icon {
  margin: 0 var(--spacer-16);
  width: 16px;
  height: 16px;
}

@media screen and (width >= 1000px) {
  .collection-list-item__icon {
    margin: 0 var(--spacer-24);
    width: 22px;
    height: 22px;
  }
}

.collection-list-item__description {
  margin: 0;
  font-size: 16px;
}

@media screen and (width >= 1000px) {
  .collection-list-item__description {
    font-size: 22px;
  }
}

.collection-list-item__short {
  font-size: 12px;
  font-weight: var(--bold);
  letter-spacing: .05em;
  margin: 0;
  line-height: 18px;
}

@media screen and (width >= 1000px) {
  .collection-list-item__short {
    font-size: 16px;
    line-height: 25px;
  }
}

.block-maintext * {
  color: inherit;
}

.block-maintext :first-child {
  margin-top: 0;
}

.block-maintext :last-child {
  margin-bottom: 0;
}

.block-maintext a {
  color: inherit;
  transition: color var(--link-transition);
  text-decoration: underline;
}

.block-maintext a:hover {
  color: var(--orange);
}

.block-maintext p {
  margin-bottom: 1em;
}

.block-video * {
  color: inherit;
}

.block-video figcaption {
  color: gray;
  font-size: 12px;
}

.block-video figure {
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  position: relative;
}

.block-video iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}



/*# sourceMappingURL=main.css.map */
