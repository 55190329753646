@font-face {
  font-family: "Space Grotesk";
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: local("Space Grotesk Light"), local("SpaceGrotesk-Light"),
    url("../fonts/space-grotesk/SpaceGrotesk-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Space Grotesk";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: local("Space Grotesk Regular"), local("SpaceGrotesk-Regular"),
    url("../fonts/space-grotesk/SpaceGrotesk-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Space Grotesk";
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: local("Space Grotesk Medium"), local("SpaceGrotesk-Medium"),
    url("../fonts/space-grotesk/SpaceGrotesk-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Space Grotesk";
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: local("Space Grotesk SemiBold"), local("SpaceGrotesk-SemiBold"),
    url("../fonts/space-grotesk/SpaceGrotesk-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Space Grotesk";
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: local("Space Grotesk Bold"), local("SpaceGrotesk-Bold"),
    url("../fonts/space-grotesk/SpaceGrotesk-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Marbla";
  src: url("../fonts/Marbla/MarblaVF.woff2") format(woff2) tech(variations),
    url("../fonts/Marbla/MarblaVF.woff") format("woff");
}

.opener-word {
  font-family: "Marbla";
  font-variation-settings: "iktr" 100, "blln" 0, "curv" 0;
}