.hero-text {
  margin: var(--spacer-48) 0;
}

@media screen and (min-width: 1000px) {
  .hero-text {
    margin: calc(3 * var(--spacer-48)) 0;
  }
}

.hero-text__kicker {
  font-size: 13px;
  margin: 0 0 var(--spacer-24);
}

@media screen and (min-width: 1000px) {
  .hero-text__kicker {
    font-size: 24px;
    margin: 0 0 var(--spacer-48);
  }
}

.hero-text__text {
  font-size: 18px;
  line-height: 25px;
  max-width: 850px;
  margin: 0 auto;
}

@media screen and (min-width: 600px) {
  .hero-text__text {
    width: 75vw;
  }
}

@media screen and (min-width: 1000px) {
  .hero-text__text {
    font-size: 36px;
    line-height: 45px;
  }
}