.global__layout-align {
  grid-column: 2;
}

iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: contain;
}
