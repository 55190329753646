.block-video * {
    color: inherit;
}

.block-video figcaption {
    font-size: 12px;
    color: grey;
}

.block-video figure {
    width: 100%;
    height: 0;
    padding-top: 56.25%;
    position: relative;
}

.block-video iframe {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
}