.navigation--slide-up {
  transform: translateY(-100%);
}

.navigation--bg-green {
  background-color: rgba(248, 255, 244, 0.7);
}

.navigation--bg-orange {
  background-color: rgba(255, 247, 234, 0.7);
}

.navigation--bg-grey {
  background-color: rgba(242, 242, 242, 0.7);
}

.navigation--open {
  bottom: 0;
  background: rgba(255, 255, 255, 0.9);
}

.navigation__inner {
  border-bottom: 1px solid black;
  padding: var(--spacer-16) 0;
  display: flex;
  justify-content: space-between;
}

@media screen and (min-width: 1000px) {
  .navigation__inner {
    padding: var(--spacer-24) 0;
  }
}

.navigation--open .navigation__inner {
  border-bottom: unset;
}

.navigation__logo {
  font-weight: var(--bold);
  font-size: 18px;
  color: inherit;
  text-decoration: none;
  line-height: 20px;
  opacity: 1;
  transition: all var(--link-transition);
}

@media screen and (min-width: 1000px) {
  .navigation__logo {
    font-size: 24px;
    line-height: 28px;
  }
}

.navigation__logo:hover {
  opacity: 0.6;
}

.navigation__menu-button {
  padding: 0;
  background: unset;
  border: unset;
  font-size: 16px;
  font-weight: var(--semi);
  cursor: pointer;
  color: black;
  transition: color var(--link-transition);
  line-height: 20px;
}

@media screen and (min-width: 1000px) {
  .navigation__menu-button {
    font-size: 20px;
    line-height: 28px;
  }
}

.navigation__menu-button:hover {
  color: var(--red);
}

.navigation__overlay-nav-list {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
  border-top: 1px solid black;
  width: 100%;
  max-width: 750px;
  transform: translateX(-1000%);
  height: 0;
  opacity: 0;
  transition: opacity 250ms ease;
}

@media screen and (min-width: 600px) {
  .navigation__overlay-nav-list {
    width: 75vw;
  }
}

.navigation--open .navigation__overlay-nav-list {
  transform: translateX(0);
  height: auto;
  margin: calc(var(--spacer-48) * 2) auto 0;
  opacity: 1;
}

@media screen and (min-width: 1000px) {
  .navigation--open .navigation__overlay-nav-list {
    margin: calc(var(--spacer-48) * 3) auto 0;
  }
}

.navigation__overlay-nav-item {
  border-bottom: 1px solid black;
  font-size: 20px;
  padding: var(--spacer-24) 0;
  font-weight: var(--semi);
}

@media screen and (min-width: 1000px) {
  .navigation__overlay-nav-item {
    font-size: 48px;
  }
}

.navigation__overlay-nav-link {
  color: inherit;
  transition: color var(--link-transition);
  text-decoration: none;
}

.navigation__overlay-nav-link:hover {
  color: var(--orange);
}