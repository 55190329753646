.default-text {
  max-width: 850px;
  margin: var(--spacer-48) auto;
  font-size: 1em;
  line-height: 1.4;
}

@media screen and (min-width: 1000px) {
  .default-text {
    margin: calc(2 * var(--spacer-48)) auto;
    font-size: 1.5em;
  }
}
