.strichschriftzeichen {
  background-color: var(--light-orange);
  margin-top: var(--nav-height);
}

.strichschriftzeichen__header {
  margin: var(--spacer-48) auto;
  max-width: 450px;
  text-align: center;
}

@media screen and (min-width: 800px) {
  .strichschriftzeichen__header {
    max-width: 550px;
  }
}

@media screen and (min-width: 1000px) {
  .strichschriftzeichen__header {
    width: 100%;
    max-width: 750px;
    margin: calc(2 * var(--spacer-48)) auto;
  }
}

.strichschriftzeichen__header-headline {
  font-size: 28px;
  margin: 0;
  font-weight: var(--semi);
}

@media screen and (min-width: 600px) {
  .strichschriftzeichen__header-headline {
    font-size: 28px;
  }
}

@media screen and (min-width: 800px) {
  .strichschriftzeichen__header-headline {
    font-size: 40px;
  }
}

@media screen and (min-width: 1000px) {
  .strichschriftzeichen__header-headline {
    font-size: 56px;
  }
}

.strichschriftzeichen__icon-wrapper {
  width: 80vw;
  padding-top: 80vw;
  height: 0;
  position: relative;
  margin: 0 auto;
  margin-top: var(--spacer-28);
}

@media screen and (min-width: 500px) {
  .strichschriftzeichen__icon-wrapper {
    width: 400px;
    padding-top: 400px;
    margin-top: var(--spacer-48);
  }
}

.strichschriftzeichen__icon {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.strichschriftzeichen__description {
  max-width: 850px;
  margin: var(--spacer-48) auto;
  font-size: 1em;
  line-height: 1.4;
}

@media screen and (min-width: 1000px) {
  .strichschriftzeichen__description {
    margin: calc(2 * var(--spacer-48)) auto;
    font-size: 1.5em;
  }
}

.strichschriftzeichen__back-button-wrapper {
  max-width: 850px;
  display: flex;
  justify-content: center;
  margin: var(--spacer-48) auto;
}

@media screen and (min-width: 1000px) {
  .strichschriftzeichen__back-button-wrapper {
    margin: calc(2 * var(--spacer-48)) auto;
  }
}

.strichschriftzeichen__back-button {
  background-color: none;
  color: black;
  border: 1px solid black;
  border-radius: 6px;
  padding: var(--spacer-8) var(--spacer-28);
  text-decoration: none;
  display: inline-block;
  margin-top: var(--spacer-24);
  transition: all var(--link-transition);
}

@media screen and (min-width: 1000px) {
  .strichschriftzeichen__back-button {
    padding: var(--spacer-12) var(--spacer-28);
    margin-top: var(--spacer-32);
  }
}

.strichschriftzeichen__back-button:hover {
  background-color: var(--green);
}

.strichschriftzeichen__description-headline {
  font-size: 1.25em;
  font-weight: var(--semi);
}
