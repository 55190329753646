.collection-page-cta {
  margin-bottom: var(--spacer-48);
}

@media screen and (min-width: 1000px) {
  .collection-page-cta {
    margin-bottom: calc(2 * var(--spacer-48));
  }
}

.collection-page-cta__inner {
  max-width: 450px;
  margin: 0 auto;
  text-align: center;
  font-weight: var(--semi);
  font-size: 14px;
  line-height: 1.5;
}

@media screen and (min-width: 1000px) {
  .collection-page-cta__inner {
    font-size: 1em;
  }
}

.collection-page-cta__button {
  background-color: var(--green);
  color: black;
  border: none;
  border-radius: 6px;
  padding: var(--spacer-8) var(--spacer-28);
  text-decoration: none;
  display: inline-block;
  margin-top: var(--spacer-24);
  transition: all var(--link-transition);
}

@media screen and (min-width: 1000px) {
  .collection-page-cta__button {
    padding: var(--spacer-12) var(--spacer-28);
    margin-top: var(--spacer-32);
  }
}

.collection-page-cta__button:hover {
  background-color: black;
  color: white;
}
