.collection-page-list {
  max-width: 750px;
  width: 100%;
  margin: 0 auto var(--spacer-48);
}

@media screen and (min-width: 600px) {
  .collection-page-list {
    width: 75vw;
  }
}

@media screen and (min-width: 1000px) {
  .collection-page-list {
    margin: 0 auto calc(2 * var(--spacer-48));
  }
}

.collection-page-list__list {
  padding: 0;
  margin: 0;
}
