.footer__bottom {
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  margin: var(--spacer-16) 0;
}

.footer__link-since {
  text-decoration: none;
  color: inherit;
  transition: color var(--link-transition);
}

.footer__link-since:hover {
  color: var(--orange);
}

.footer__sub-nav {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.footer__sub-nav-link {
  text-decoration: none;
  color: inherit;
  transition: color var(--link-transition);
}

.footer__sub-nav-link:hover {
  color: var(--orange);
}
