.block-maintext * {
    color: inherit;
}

.block-maintext *:first-child {
    margin-top: 0;
}

.block-maintext *:last-child {
    margin-bottom: 0;
}

.block-maintext a {
    color: inherit;
    transition: color var(--link-transition);
    text-decoration: underline;
}

.block-maintext a:hover {
    color: var(--orange);
}

.block-maintext p {
    margin-bottom: 1em;
}