.hero-two-panel-text-image__headline {
  line-height: 1.1em;
  font-weight: var(--semi);
  margin: var(--spacer-48) 0 var(--spacer-32);
  text-align: center;
}

@media screen and (min-width: 760px) {
  .hero-two-panel-text-image__headline {
    text-align: left;
    margin: calc(1.5 * var(--spacer-48)) 0;
  }
}

@media screen and (min-width: 1000px) {
  .hero-two-panel-text-image__headline {
    margin: calc(3 * var(--spacer-48)) 0;
    text-align: left;
  }
}

.hero-two-panel-text-image__image-box {
  padding-top: 150px;
  height: 0;
  width: 80%;
  max-width: 400px;
  position: relative;
  align-self: center;
  justify-self: center;
  margin-bottom: var(--spacer-32);
}

@media screen and (min-width: 760px) {
  .hero-two-panel-text-image__image-box {
    padding-top: 200px;
    margin-bottom: unset;
  }
}

@media screen and (min-width: 1000px) {
  .hero-two-panel-text-image__image-box {
    padding-top: 400px;
  }
}

.hero-two-panel-text-image__image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
}

.hero-two-panel-text-image__quick-links {
  border-top: 1px solid black;
}

@media screen and (min-width: 760px) {
  .hero-two-panel-text-image__quick-links {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    display: grid;
    grid-template-columns: repeat(2, minmax(1px, 1fr));
  }
}

.hero-two-panel-text-image__quick-link-wrapper {
  padding: var(--spacer-16) 0;
  border-bottom: 1px solid black;
}

@media screen and (min-width: 760px) {
  .hero-two-panel-text-image__quick-link-wrapper {
    border-right: 1px solid black;
    border-bottom: unset;
    display: flex;
    justify-content: center;
  }

  .hero-two-panel-text-image__quick-link-wrapper:last-child {
    border-right: unset;
  }
}

@media screen and (min-width: 1000px) {
  .hero-two-panel-text-image__quick-link-wrapper {
    padding: var(--spacer-24) 0;
  }
}

.hero-two-panel-text-image__quick-link-wrapper-inner {
  display: flex;
  align-items: center;
  cursor: pointer;
}

@media screen and (min-width: 1000px) {
  .hero-two-panel-text-image__quick-link-wrapper-inner {
    justify-content: center;
  }
}

.hero-two-panel-text-image__quick-link {
  text-align: center;
  font-size: 18px;
  font-weight: var(--semi);
  text-decoration: none;
  color: black;
  position: relative;
  transition: color var(--link-transition);
}

@media screen and (min-width: 1000px) {
  .hero-two-panel-text-image__quick-link {
    font-size: 28px;
  }
}

@media screen and (min-width: 1200px) {
  .hero-two-panel-text-image__quick-link {
    font-size: 36px;
  }
}

.hero-two-panel-text-image__quick-link-wrapper-inner:hover .hero-two-panel-text-image__quick-link {
  color: var(--orange);
}

.hero-two-panel-text-image__quick-link-pfeil {
  width: 20px;
  height: 100%;
  object-fit: contain;
  margin-left: var(--spacer-8);
  fill: black;
  transition: fill var(--link-transition);
}

@media screen and (min-width: 1000px) {
  .hero-two-panel-text-image__quick-link-pfeil {
    width: 40px;
    margin-left: var(--spacer-16);
  }
}

.hero-two-panel-text-image__quick-link-wrapper-inner:hover .hero-two-panel-text-image__quick-link-pfeil {
  fill: var(--orange);
}