@import "./font-import.css";
@import "./tailwind.css";
@import "./globals.css";
@import "./writer.css";

/* templates */
@import "./templates/strickschriftzeichen.css";

/* snippets */
@import "./snippets/default-text.css";
@import "./snippets/footer.css";
@import "./snippets/navigation.css";
@import "./snippets/hero-two-panel-text-image.css";
@import "./snippets/hero-text.css";
@import "./snippets/collection-page-header.css";
@import "./snippets/collection-page-cta.css";
@import "./snippets/collection-page-list.css";
@import "./snippets/collection-list-item.css";

/* blocks */
@import "./block-maintext.css";
@import "./block-video.css";

/* .col-span-1 {
    grid-column: span 1 / span 1;
}

.col-span-2 {
    grid-column: span 2 / span 2;
}

.grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
} */