.writer *:first-child {
  margin-top: 0;
}

.writer *:last-child {
  margin-bottom: 0;
}

.writer * {
  color: inherit;
}

.writer a {
  color: inherit;
  transition: color var(--link-transition);
  text-decoration: underline;
}

.writer a:hover {
  color: var(--orange);
}

.writer p {
  margin-bottom: 1em;
}