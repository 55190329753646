.collection-page-header {
  margin: var(--spacer-48) auto;
  max-width: 450px;
  text-align: center;
}

@media screen and (min-width: 800px) {
  .collection-page-header {
    max-width: 550px;
  }
}

@media screen and (min-width: 1000px) {
  .collection-page-header {
    width: 100%;
    max-width: 750px;
    margin: calc(2 * var(--spacer-48)) auto;
  }
}

.collection-page-header__headline {
  font-size: 28px;
  margin: 0;
  font-weight: var(--semi);
}

@media screen and (min-width: 600px) {
  .collection-page-header__headline {
    font-size: 28px;
  }
}

@media screen and (min-width: 800px) {
  .collection-page-header__headline {
    font-size: 40px;
  }
}

@media screen and (min-width: 1000px) {
  .collection-page-header__headline {
    font-size: 56px;
  }
}

.collection-page-header__text {
  font-size: 18px;
  line-height: 1.3;
  margin: var(--spacer-16) 0 0;
}

@media screen and (min-width: 600px) {
  .collection-page-header__text {
    font-size: 20px;
    margin: var(--spacer-24) 0 0;
  }
}

@media screen and (min-width: 800px) {
  .collection-page-header__text {
    font-size: 24px;
    margin: var(--spacer-32) 0 0;
  }
}

@media screen and (min-width: 1000px) {
  .collection-page-header__text {
    font-size: 32px;
  }
}
