:root {
  --collection-list-item-col: 70px;
  --collection-list-item-col-abk: 80px;
}

@media screen and (min-width: 1000px) {
  :root {
    --collection-list-item-col: 100px;
    --collection-list-item-col-abk: 120px;
  }
}

.collection-list-item__header {
  border-bottom: 1px solid black;
  list-style: none;
  display: grid;
  grid-template-columns: var(--collection-list-item-col) 1fr;
  font-weight: var(--semi);
  font-size: 12px;
  padding-bottom: var(--spacer-8);
  letter-spacing: 0.025em;
}

.collection-list-item__header--abkuerzung {
  grid-template-columns: var(--collection-list-item-col-abk) 1fr;
}

.collection-list-item {
  border-bottom: 1px solid black;
  list-style: none;
  display: grid;
  grid-template-columns: var(--collection-list-item-col) 1fr;
  padding: var(--spacer-16) 0;
}

.collection-list-item--abkuerzung {
  grid-template-columns: var(--collection-list-item-col-abk) 1fr;
}

@media screen and (min-width: 1000px) {
  .collection-list-item {
    padding: var(--spacer-24) 0;
  }
}

.collection-list-item__icon {
  width: 16px;
  height: 16px;
  margin: 0 var(--spacer-16);
}

@media screen and (min-width: 1000px) {
  .collection-list-item__icon {
    width: 22px;
    height: 22px;
    margin: 0 var(--spacer-24);
  }
}

.collection-list-item__description {
  margin: 0;
  font-size: 16px;
}

@media screen and (min-width: 1000px) {
  .collection-list-item__description {
    font-size: 22px;
  }
}

.collection-list-item__short {
  margin: 0;
  font-size: 12px;
  font-weight: var(--bold);
  letter-spacing: 0.05em;
  line-height: 18px;
}

@media screen and (min-width: 1000px) {
  .collection-list-item__short {
    font-size: 16px;
    line-height: 25px;
  }
}
